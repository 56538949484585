<template>
	<div class="whitelist" id="gooniez">
		<h1 class="fade">Gooniez Gang</h1>
		<p class="fade">
			An NFT collection of 8888 Gooniez – unique 3D characters built with a sophisticated variety of unique traits on the Ethereum blockchain. The Gooniez NFT will be packed with utility, serving more than a cool PFP, Gooniez Gang NFT will serve as a unique playable racer in our ever-expanding Metaverse game being built, Gooniez Gang Apex!		</p>
		<a href="discord.com/invite/gooniez" target="_blank" class="join-discord-cta">
			Join Discord
		</a>
		<img src="../assets/whitelist.png" alt="Gooniez Gang" class="mobile-whitelist-bg" />
	</div>
</template>

<script>

export default {

}
</script>

<style lang="scss" scoped>
.whitelist {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: 100vh;
	background: #070707;
	@media (min-width: 1024px) {
		justify-content: flex-start;
		width: 100vw;
		height: max-content;
	}
	& .mobile-whitelist-bg {
		width: 100vw;
		z-index: 1;
		@media (min-width: 1024px) {
			margin-top: -25vh;
		}
	}
	& h1 {
		text-align: center;
		color: white;
		margin: 10vh 0 0;
		line-height: 0.8;
		font-size: 3.5rem;
		z-index: 2;
		@media (min-width: 1024px) {
			font-size: 7rem;
			margin: 10vh 0;
		}
		@media (min-width: 1400px) {
			margin: 13vh 0 5vh;
		}
	}
	& p {
		font-family: 'Body';
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		text-align: center;
		text-transform: uppercase;
		color: #FFFFFF;
		max-width: 80vw;
		margin-top: 5vh;
		z-index: 2;
		@media (min-width: 1024px) {
			max-width: 40vw;
			margin-top: 0;
		}
		@media (min-width: 1400px) {
			max-width: 40vw;
		}
	}
	& a {
		background: transparent;
		border-radius: 16px;
		color: white;
		font-family: 'Body';
		font-size: 20px;
		font-weight: 500;
		padding: 16px 72px;
		text-transform: uppercase;
		border: 1px solid white;
		transition: all 0.5s ease;
		margin: 5vh 0;
		z-index: 2;
		@media (min-width: 1024px) {
			margin: 5vh 0;
		}
		&:hover {
			background: white;
			color: black;
			border-color: black;
		}
	}
}
	
</style>