<template>
    <modal @close="close" :z-index="zIndex">
        <div>
            <div class="connect-top-row">
                <h1 class="font-weight-bold">Connect Your Wallet</h1>
                <button @click="close">X</button>
            </div>
            <div class="connect-middle-row"><a class="connect-btn metamask-connect oauth" href="#" @click="connectMM()"><img src="./img/metamask.svg" /></a><a class="connect-btn trust-wallet-connect oauth" href="#" @click="connectWC()"><img src="./img/WalletConnect.svg" /></a><a class="connect-btn coinbase-connect oauth" href="#" @click="connectCB()"><img src="./img/coinbase.svg" /></a>
                <div class="connect-bottom-row"><a class="learn-connect" href="#" @click="$emit('show-guide-modal')">Learn how to open a wallet</a></div>
            </div>
        </div>
    </modal>
</template>

<script>

// import WalletConnect from "@walletconnect/client";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3EthContract from "web3-eth-contract";

import WalletLink from 'walletlink'
import Web3 from 'web3'

const APP_NAME = 'Gooniez Gang'
const APP_LOGO_URL = 'https://test-gooniez.vercel.app/img/roadmap1.d37453fe.png'
const DEFAULT_ETH_JSONRPC_URL = 'https://mainnet.infura.io/v3/0bd43efad2734160b5fbc1be92c69e17'
const DEFAULT_CHAIN_ID = 1

import Modal from './Modal'

  export default {
    name: 'WalletPopup',
    components: {
        Modal
    },

    props: {
        zIndex: Number
    },

    methods: {
      close(success) {
        this.$emit('close', success);
      },

      updateAccount(publicAddress) {
        this.$store.commit('setPublicAddress', {
          publicAddress: publicAddress
        });
      },

      saveSmartContract(smartContract) {
        this.$store.commit('setSmartContract', {
          smartContract
        });
      },

      saveWeb3(web3) {
        this.$store.commit('setWeb3', {
          web3
        })
      },

      async connectWallet(provider) {
        let web3 = new Web3(provider);
        await provider.enable().then( async (accounts) => {
          const networkId = await this.getNetworkId(provider);
          if (networkId != this.$store.getters.getConfigID) {
            try {
              await provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${this.$store.getters.getConfigID}` }], // chainId must be in hexadecimal numbers
              });
            } catch (err) {
              console.log({ err });
              alert('Please change to the Ethereum Mainnet');
              return;
            }
          }

          const SmartContractObj = new Web3EthContract(
            this.$store.getters.getAbi,
            this.$store.getters.getContractAddress,
          );

          this.saveSmartContract(SmartContractObj);
          this.saveWeb3(web3);
          this.updateAccount(accounts[0]);
          this.setEventHandlers(provider);
          this.close(true);
        });
      },

      async getNetworkId(provider) {
        return await provider.request({
          method: "net_version",
        });
      },

      setEventHandlers(provider) {
        provider.on("accountsChanged", (accounts) => {
          this.updateAccount(accounts[0]);
        });

        provider.on("chainChanged", async () => {
          const networkId = await this.getNetworkId(provider);
          if (networkId != this.$store.getters.getConfigID) {
            alert('Please switch to the Ethereum Mainnet');
          }
        });

        provider.on('disconnect', (error) => {
          if (error) {
            throw error;
          }

          this.updateAccount(null);
        });
      },

      async connectMM() {
        const { ethereum } = window;

        let metamaskProvider = null;
        if (ethereum && ethereum.providers) {
            metamaskProvider = ethereum.providers.find((provider) => provider.isMetaMask);
        } else {
            metamaskProvider = ethereum && ethereum.isMetaMask ? ethereum : null;
        }

        const metamaskIsInstalled = ethereum && metamaskProvider;
        if (metamaskIsInstalled) {
            try {
              this.connectWallet(metamaskProvider);
            } catch (err) {
                console.log(err);
            }
        } else {
          window.location = 'https://metamask.app.link/dapp/test-gooniez.vercel.app/';
        }
    },

    async connectCB() {
      // Initialize WalletLink
      const walletLink = new WalletLink({
        appName: APP_NAME,
        appLogoUrl: APP_LOGO_URL,
        darkMode: false
      })

      const ethereum = walletLink.makeWeb3Provider(DEFAULT_ETH_JSONRPC_URL, DEFAULT_CHAIN_ID);

      if (ethereum) {
        try {
          await this.connectWallet(ethereum);
        } catch (err) {
          console.log(err);
        }
      }
    },

    async connectWC() {
      const provider = new WalletConnectProvider({
        infuraId: "0bd43efad2734160b5fbc1be92c69e17", // Required
      });

      if (provider) {
        try {
          await this.connectWallet(provider);
        } catch(err) {
          console.log(err);
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
  .connect-top-row {
    display: flex;
    margin: 0;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    & h1 {
        font-size: 32px;
        margin-bottom: 0;
        text-align: center;

        @media (min-width: 1024px) {
        font-size: 90px;
        }
    }

        & button {
            font-weight: 500;
            font-size: 20px;
            position: absolute;
            right: 6vw;
            top: 2vh;
            background: none;
            border: none;
            border-radius: none;
            color: inherit;
            font: inherit;
            outline: none;
            pointer-events: auto;

            @media (min-width: 1024px) {
                top: 3vh;
                right: 2vw;
            }
        }
    }

.connect-middle-row {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .metamask-connect {
    margin-top: 5vh;

    & img {
      transform: scale(0.15);
      @media (min-width: 1024px) {
        transform: scale(0.7);
      }
    }
  }

  & .trust-wallet-connect {
    & img {
      transform: scale(0.15);
      @media (min-width: 1024px) {
        transform: scale(0.75);
      }
    }
  }

  & .coinbase-connect {
    & img {
      transform: scale(0.7);

      @media (min-width: 1024px) {
        transform: scale(1);
      }
    }
  }

  & .oauth {
    align-items: center;
    background: white;
    border-radius: 94px;
    display: flex;
    justify-content: center;
    margin-bottom: 17px;
    transition: all 0.3s ease;
    width: 70vw;
    height: 50px;

    @media(min-width: 1024px) {
      height: 70px;
      width: 405px;
    }

    &:hover {
      transform: translateY(-5px);
    }
  }

  & .learn-connect {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    line-height: 154.2%;
    margin-top: 5vh;
    text-align: center;
    text-decoration-line: underline;
    text-transform: uppercase;
    font-family: 'Body';
  }
}
    

</style>