<template>
    <modal @close="close" :z-index="zIndex">
        <div>
            <div class="wallet-top-row">
                <h1 class="font-weight-bold">How To Create Wallet</h1>
                <button @click="close">X</button>
            </div>
            <div class="wallet-row">
                <div class="wallet-col first-wallet-col">
                    <h1>1</h1>
                    <p>add extension in chrome <br /><br /> click &ldquo;add to chrome&rdquo;
                        to complete the installation</p>
                </div>
                <div class="wallet-col">
                    <h1>2</h1>
                    <p>create your account or use your recovery phrase <br /><br /> import a wallet or set up a new one</p>
                </div>
                <div class="wallet-col">
                    <h1>3</h1>
                    <p>COME BACK TO OUR WEBSITE AND CLICK CONNECT<br /><br /> UPLOAD FUNDS TO YOUR METAMASK AND PURCHASE THE NFT</p>
                </div>
                <div class="wallet-col last-wallet-col">
                    <h1>4</h1>
                    <p>COME BACK TO OUR WEBSITE AND CLICK CONNECT<br /><br /> UPLOAD FUNDS TO YOUR METAMASK AND PURCHASE THE NFT</p>
                </div>
            </div>
            <div class="wallet-bottom-row">
                <h1>Still need help?</h1><a class="watch-videos" href="#">Watch Videos</a>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from './Modal'

  export default {
    name: 'WalletGuidePopup',
    components: {
        Modal
    },

    props: {
        zIndex: Number
    },

    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style lang="scss" scoped>

.wallet-top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  & h1 {
    margin-bottom: 0;
    font-size: 32px;

    @media (min-width: 1024px) {
      font-size: 90px;
    }
  }

    & button {
        font-weight: 500;
        font-size: 20px;
        position: absolute;
        right: 6vw;
        top: 2vh;
        background: none;
        border: none;
        border-radius: none;
        color: inherit;
        font: inherit;
        outline: none;
        pointer-events: auto;

        @media (min-width: 1024px) {
            top: 3vh;
            right: 2vw;
        }
    }
}


.wallet-row {
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    margin-top: 8vh;
  }
}

.wallet-col {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px solid white;

  @media (min-width: 1024px) {
    border-right: 1px solid white;
    padding: 20px 50px;
  }

  & h1 {
    font-size: 32px;

    @media (min-width: 1024px) {
      font-size: 90px;
      height: 100px;
    }
  }

  & p {
    font-size: 10px;
    text-transform: uppercase;
    color: #FFFFFF;
    font-weight: 400;
    margin-bottom: 0;

    @media (min-width: 1024px) {
      font-size: 14px;
      line-height: 154.2%;
    }
  }
}

.first-wallet-col {
  border-top: 1px solid white;
  margin-top: 3vh;

  @media (min-width: 1024px) {
    border-top: none;
    margin-top: 0;
  }

  padding-left: 0;
}

.last-wallet-col {
  border-right: none;
}

.wallet-bottom-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3vh;

  @media (min-width: 1024px) {
    margin-top: 8vh;
  }

  & h1 {
    font-size: 20px;
    margin-bottom: 0;

    @media (min-width: 1024px) {
      font-size: 90px;
    }
  }

  & a {
    background: #FFFFFF;
    border-radius: 94px;
    font-weight: bold;
    color: #282828;
    font-size: 10px;
    filter: drop-shadow(0px 4px 41px rgba(255, 255, 255, 0.51));
    padding: 10px 20px;

    @media (min-width: 1024px) {
      padding: 20px 70px;
    }
  }
}
    

</style>