<template>
	<div class="faq" id="faq">
		<h1 class="fade">FAQS</h1>
		<div class="accordion__wrapper">
			<button class="accordion">What is the Total Supply of Gooniez?</button>
			<div class="panel">
			<p>There are a total of 8,888 NFTs available to mint.</p>
			</div>

			<button class="accordion">What is the price for one Gooniez NFT?</button>
			<div class="panel">
			<p>WL .15 | Public Sales .25 ETH</p>
			</div>

			<button class="accordion">How do I know if I'm on the Whitelist?</button>
			<div class="panel">
			<p>
				· You will have the @Whitelist (Gooniez WL) role or the @Gooniez Whitelist role on your discord profile!<br/>
				· Make sure to read &LT;#929436443426832404&GT; frequently so you don't accidentally miss out!
			</p>
			</div>

			<button class="accordion">What day does Gooniez Launch?</button>
			<div class="panel">
			<p>
				Gooniez Whitelist will be Minting on February TBD
				Gooniez Public will be Minting on February TBD
			</p>
			</div>

			<button class="accordion">How do I join the whitelist?</button>
			<div class="panel">
			<p>
				Head to the &LT;#929436443426832404&GT; and follow the directions there!
			</p>
			</div>

			<button class="accordion">
				How many people will be allowed in the Whitelist Sale?
			</button>
			<div class="panel">
			<p>
				There will only be 3500 people Allowed/Eligible to be in our Whitelist Sale
			</p>
			</div>

			<button class="accordion">
				How many Gooniez NFTs can I mint in the Whitelist Sale?
			</button>
			<div class="panel">
			<p>
				You May Mint 2 NFTs Per Wallet If you are in the Whitelist Sale. 
			</p>
			</div>

			<button class="accordion">
				How many Gooniez NFTs can I mint in the Public Sale?
			</button>
			<div class="panel">
			<p>
				You May Mint up-to 2 Gooniez NFTs Per Wallet If you are in the Public Sale.
			</p>
			</div>
		</div>
		<div class="footer">
			<div class="left">
				<img src="../assets/eye_logo.svg" alt="Gooniez Gang Logo">
				<small>Copyright 2022 Gooniez Gang</small>
			</div>
			<div class="right">
				<a href="https://twitter.com/GooniezNFT" target="_blank">
					<img src="../assets/twitter-white.svg" alt="Gooniez Gang Twitter">
				</a>
				<!-- <a href="">
					<img src="../assets/telegram.svg" alt="Gooniez Gang Telegram">
				</a> -->
				<a href="https://www.instagram.com/gooniezgangnft/" target="_blank">
					<img src="../assets/instagram.svg" alt="Gooniez Gang Instagram">
				</a>
				<a href="discrd.com/invite/gooniez" target="_blank">
					<img src="../assets/discord-white.svg" alt="Gooniez Gang Discord">	
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default {
	mounted() {
		var acc = document.getElementsByClassName("accordion");
		var i;

		for (i = 0; i < acc.length; i++) {
			acc[i].addEventListener("click", function() {
				this.classList.toggle("active");
				var panel = this.nextElementSibling;
				if (panel.style.maxHeight) {
					panel.style.maxHeight = null;
				} else {
					panel.style.maxHeight = panel.scrollHeight + "px";
				}

				setTimeout(() => {
					ScrollTrigger.refresh();
				}, 3000);
			});
		}
	}
}
</script>

<style lang="scss" scoped>
	.faq {
		padding: 0 7vw;
		margin-top: 100px;
		padding-bottom: 5vh;
		@media (min-width: 1024px) {
			padding-bottom: 2vh;
		}
		& h1 {
			color: white;
			font-family: 'Soulcraft';
			font-weight: normal;
			margin: 0 auto;
			font-size: 20vw;
			text-align: left;
			text-transform: uppercase;
			padding-bottom: 5vh;
			@media (min-width: 1024px) {
				padding-bottom: 10vh;
				font-size: 7rem;
			}
		}
	}	
	.accordion {
		color: #fff;
		cursor: pointer;
		padding: 18px;
		width: 100%;
		border: none;
		text-align: left;
		font-size: 1.2rem;
		outline: none;
		transition: all 0.5s ease;
		font-family: 'Soulcraft', sans-serif;
		background-color: black;
		padding-left: 0;
		text-transform: uppercase;
		@media (min-width: 1024px) {
			font-size: 30pt;
		}
	}


	.accordion:after {
		content: '+';
		color: #fff;
		font-weight: bold;
		float: right;
		margin-left: 5px;
	}

	.active:after {
		content: "-";
	}

	.panel {
		color: white;
		font-family: 'Body';
		font-size: 16px;
		font-style: normal;
		font-weight: 300;
		max-height: 0;
		overflow: hidden;
		padding: 0 18px;
		text-align: left;
		text-transform: uppercase;
		padding-left: 0;
		transition: max-height 0.2s ease-out;
		border-bottom: 1px solid white;
		text-transform: uppercase;

	}
	.right {
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>