<template>
  <div>
	<div class="preloader"><p>LOADING...</p></div>
	<div class="home smooth-scroll" ref="scroll">
		<Landing />
		<!-- <SaleInfo /> -->
		<!-- <div class="mint">
			<div class="mint__item">
				<a class="mint__item-link">MINT</a>
				<div class="marquee">
					<div class="marquee__inner" aria-hidden="true">
						<span>MINT</span>
						<span>MINT</span>
						<span>MINT</span>
						<span>MINT</span>
						<span>MINT</span>
						<span>MINT</span>
						<span>MINT</span>
					</div>
				</div>
			</div>
		</div> -->	
		<AboutUs />
		<Testimonials />
		<Video2 />
		<!-- <Swiper /> -->
		<Whitelist />
		<!-- <div class="mint join-discord-marquee">
			<div class="mint__item">
				<a class="mint__item-link jd">JOIN DISCORD</a>
				<div class="marquee jd">
					<div class="marquee__inner" aria-hidden="true">
						<span>JOIN DISCORD</span>
						<span>JOIN DISCORD</span>
						<span>JOIN DISCORD</span>
						<span>JOIN DISCORD</span>
					</div>
				</div>
			</div>
		</div> -->
		<Roadmap />
		<!-- <Video /> -->
		<!-- <Team/> -->
		<!-- <MobileTeam v-show="isMobile"/> -->
		<!-- <div class="mint">
			<div class="mint__item">
				<a class="mint__item-link">MINT</a>
				<div class="marquee">
					<div class="marquee__inner" aria-hidden="true">
						<span>MINT</span>
						<span>MINT</span>
						<span>MINT</span>
						<span>MINT</span>
						<span>MINT</span>
						<span>MINT</span>
						<span>MINT</span>
					</div>
				</div>
			</div>
		</div> -->
		<div class="frame">
		</div>
		<!-- <Giveaway /> -->
		<Artists />
		<FAQ />
	</div>
  </div>
</template>

<script>
import AboutUs from '../components/AboutUs'
import Whitelist from '../components/Whitelist'
// import Swiper from '../components/Swiper'
// import SaleInfo from '../components/SaleInfo'
import Roadmap from '../components/Roadmap'
// import Giveaway from '../components/Giveaway'
// import Team from '../components/Team'
import FAQ from '../components/FAQ'
import Landing from '../components/Landing'
import Video2 from '../components/Video2'
import Artists from '../components/Artists'
import Testimonials from '../components/Testimonials'

import LocomotiveScroll from 'locomotive-scroll'
import imagesLoaded from 'imagesloaded'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'

gsap.registerPlugin(ScrollTrigger, SplitText)

import { isMobile } from 'mobile-device-detect'

export default {
  name: 'Home',
  components: {
	AboutUs,
	// SaleInfo,
	Whitelist,
	Roadmap,
	// Giveaway,
	// Team,
	FAQ,
	Landing,
	Testimonials,
	Video2,
	Artists,
  },
  computed: {
	isMobile() {
		return isMobile ? true : false
	}
  },

  data() {
    return {
      loco: null
    };
  },

  mounted() {
	// let locoScroll = this.loco;
    const elScroller = ".smooth-scroll";
    const elMain = document.querySelector('.main');
    const elPreloader = document.querySelector('.preloader');
    const elPreloadertext = document.querySelector('.preloader p');

	// Config
	const config = () => {
		gsap.defaults({
			ease: "power4.out",
			duration: "1.6"
		});

		ScrollTrigger.defaults({
			start: "top bottom-=20%",
			scroller: this.$refs.scroll
		});	
		
		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
			ScrollTrigger.config({
				autoRefreshEvents: "visibilitychange,DOMContentLoaded,load"
			});
		}
	}

	const navbar = () => {
        const elMenu = document.querySelector('.hamburger');

        const tlNavbar = gsap.timeline({
          paused: true,
          defaults: {
            ease: "expo.inOut",
            duration: 1.2
          },
        });

        tlNavbar.to('.nav-overlay', {
          y: 0,
          display: "block"
        });

        tlNavbar.to('.nav', {
          background: "transparent",
          duration: 0.8
        }, "<");

        tlNavbar.to('.nav-overlay a', {
          y: 0,
          duration: 1.4,
          stagger: 0.08,
          expo: "expo.out",
        }, "<35%");

        tlNavbar.to('.socmed-2', {
          opacity: 1,
        }, "<20%");

        tlNavbar.to('.btn-connect, .socmed, .mobile-gg-logo', {
          duration: 1.0,
          autoAlpha: 0,
          ease: "power4.out"
        }, 0);

        elMenu.addEventListener('click', () => {
          if (!elMenu.classList.contains('active')) {
            tlNavbar.play();
            elMenu.classList.add('active');
          } else {
            tlNavbar.reverse();
            elMenu.classList.remove('active');
          }
        })

       gsap.utils.toArray('.link-tosection').forEach((el) => {
		const getUrl = el.getAttribute('href');
		el.addEventListener('click', (e) => {
			e.preventDefault();
			if (el.classList.contains('noscroll')) return;
			tlNavbar.reverse();
			elMenu.classList.remove('active');
			this.loco.scrollTo(getUrl, {
			offset: -100
			});
		});
		});
	}

	const locomotive = () => {
		try {
			this.loco = new LocomotiveScroll({
			el: document.querySelector(elScroller),
			smooth: true,
			lerp: 0.09,
			getDirection: true
		});

		const locoScroll = this.loco;

		const scrollUpdate = (instance) => {
			document.documentElement.setAttribute('data-direction', instance.direction);
			ScrollTrigger.update();
		}

		// each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
		locoScroll.on('scroll', (instance) => {
			scrollUpdate(instance);
		});

		ScrollTrigger.scrollerProxy(elScroller, {
			scrollTop(value) {
			return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y;
			}, // we don't have to define a scrollLeft because we're only scrolling vertically.
			getBoundingClientRect() {
			return {
				top: 0,
				left: 0,
				width: window.innerWidth,
				height: window.innerHeight
			};
			},
			// LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
			pinType: document.querySelector(elScroller).style.transform ? 'transform' : 'fixed'
		});

		ScrollTrigger.addEventListener('refresh', () => locoScroll.update());

		ScrollTrigger.refresh();
		} catch (error) {
			return error;
		}
		
	}

	const universalanim = () => {
        gsap.utils.toArray('.reveal-char').forEach((el) => {
          const elText = new SplitText(el, {
            type: 'lines, chars',
            linesClass: 'line',
            charsClass: 'char',
          });

          // gsap.utils.toArray(elText.lines).forEach((el) => {
          //   const wrapper = document.createElement('div');
          //   wrapper.classList.add('masking-text');
          //   el.parentNode.insertBefore(wrapper, el);
          //   wrapper.appendChild(el);
          // });

          const tlText = gsap.timeline({
            defaults: {
              ease: "power4.out"
            },
            scrollTrigger: {
              trigger: el
            }
          });

          tlText.from(elText.chars, {
            // yPercent: 110,
            scaleY: 2.6,
            opacity: 0,
            transformOrigin: "top",
            stagger: 0.03,
          });
        });

        gsap.utils.toArray('.fade').forEach((el) => {
          const tlFade = gsap.timeline({
            scrollTrigger: {
              trigger: el
            }
          });

          tlFade.from(el, {
            y: 40,
            opacity: 0,
            stagger: 0.10
          });
        });

        gsap.utils.toArray('.clipleft').forEach((el) => {
          const tlClip = gsap.timeline({
            defaults: {
              ease: "expo.out",
            },
            scrollTrigger: {
              trigger: el
            }
          });

          tlClip.fromTo(el, {
            clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
            opacity: 0
          }, {
            clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
            opacity: 1,
            stagger: 0.10
          });
        });
	}

	const sectionanim = () => {
        gsap.to('.badge', {
          ease: "none",
          rotation: 180,
          scrollTrigger: {
            trigger: ".badge",
            start: 'top bottom',
            end: 'bottom top',
            scrub: true,
          }
        });
	}

	const preloader = async (_callback) => {
        function preloadImages(selector) {
          return new Promise((resolve) => {
            imagesLoaded(selector, resolve);
          });
        }
        const Loaded = () => {
          const tlComplete = gsap.timeline({
            defaults: {
              duration: 1.2
            }
          });

          tlComplete.to(elPreloadertext, {
            y: 150,
            display: "none",
            ease: 'expo.inOut'
          });

          tlComplete.to(elPreloader, {
            xPercent: 100,
            display: 'none',
            ease: 'expo.inOut',
            duration: 1.0
          }, "<50%");

          _callback();
          };

        return Promise.all([preloadImages(elMain)]).then(() => {
          Loaded();
        }).catch(() => {
          Loaded();
        });
	}

	config();
	navbar();

	preloader(() => {
		locomotive();
		universalanim();
		sectionanim();
	});	
  },
  
  destroyed() {
	this.loco.destroy();
	ScrollTrigger.getAll().forEach(t => t.kill(false));
  }
}
</script>

<style lang="scss" scoped>
.masking-text {
  overflow: hidden;
  display: block;

  .line {
    position: relative;
  }
}

.smooth-scroll {
  overflow: hidden !important;
}

.preloader {
  background: black;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;

  p {
    position: absolute;
    right: 30px;
    bottom: 0px;
    text-align: right;
    font-size: 3rem;
    line-height: 0;
  }
}

.home {
  position: relative;
  z-index: 1;
  background: black;
}

.home-landing-bg {
  width: 100vw;
  height: auto;
}

a {
	text-decoration: none;
	outline: none;
}

a:hover,
a:focus {
	color: black;
	outline: none;
}

h1 {
	font-family: 'Soulcraft';
}

.mint {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	--marquee-width: 100vw;
	--offset: 20vw;
	--move-initial: calc(-25% + var(--offset));
	--move-final: calc(-50% + var(--offset));
	--item-font-size: 10vw;
	counter-reset: mint;
	background: white;
	width: 100vw;
}

.join-discord-marquee {
	margin-bottom: 0;
}

.mint__item {
	cursor: default;
	position: relative;
	padding: 0 5vw;
}

.mint__item-link {
	display: inline-block;
	cursor: pointer;
	position: relative;
	font-weight: 100;
	font-size: 50px;
	opacity: 0;
	@media (min-width: 1024px) {
		font-size: 90px;
	}
}

.mint__item-link::before {
	all: initial;
	font-family: 'Soulcraft', sans-serif;
	font-weight: 100;
	counter-increment: mint;
	position: absolute;
	bottom: 60%;
	left: 0;
	pointer-events: none;
}

.mint__item-link:hover {
	transition-duration: 0.1s;
	opacity: 0;
}

.mint__item-img {
	pointer-events: none;
	position: absolute;
	height: 50vh;
	max-height: 400px;
	opacity: 0;
	left: 100%;
	top: 50%;
	transform: translate3d(calc(-100% - 6vw),-30%,0) translate3d(0,20px,0);
}

.mint__item-link:hover + .mint__item-img {
	opacity: 1;
	transform: translate3d(calc(-100% - 6vw),-30%,0) rotate3d(0,0,1,4deg);
	transition: all 0.4s;
}

/* Make sure 3 items are visible in the viewport by setting suitable font size. */

.marquee {
	position: absolute;
	top: 0;
	left: 0;
	width: var(--marquee-width);
	overflow: hidden;
	pointer-events: none;
}

.marquee__inner {
	width: fit-content;
	display: flex;
	position: relative;
	transform: translate3d(var(--move-initial), 0, 0);
	animation: marquee 5s linear infinite;
	opacity: 1;
	transition: opacity 0.1s;
}

.mint__item-link:hover ~ .marquee .marquee__inner {
	animation-play-state: running;
	opacity: 1;
	transition-duration: 0.4s;
}

.marquee span {
	text-align: center;
}
.mint {
	.mint__item-link,
	.marquee span {
		white-space: nowrap;
		padding: 0 1vw;
		font-size: 20vw;
		font-weight: 100;
		line-height: 1.15;
		@media (min-width: 1024px) {
			font-size: 10vw;
		}
	}
}
.join-discord-marquee {
	.jd, .jd span {
		white-space: nowrap;
		padding: 0 1vw;
		font-size: 15vw;
		font-weight: 100;
		line-height: 1.15;
		@media (min-width: 1024px) {
			font-size: 10vw;
		}
	}
}

.marquee span {
	font-style: italic;
}

@keyframes marquee {
	0% {
		transform: translate3d(var(--move-initial), 0, 0);
	}

	100% {
		transform: translate3d(var(--move-final), 0, 0);
	}
}

@media screen and (min-width: 53em) {
	.frame {
		text-align: left;
		display: grid;
		align-content: space-between;
		width: 100%;
		padding: 2.5rem 6vw;
		pointer-events: none;
		grid-template-columns: 75% 25%;
		grid-template-rows: auto;
	}

	.frame__title-wrap {
		display: flex;
		align-items: flex-start;
	}

	.frame__info {
		margin: 0;
	}

	.frame__title {
		margin: 0 1rem 0 0;
	}

	.frame__links {
		padding: 0;
		justify-self: end;
	}

	.frame a {
		pointer-events: auto;
	}
}

.join-discord-cta {
	background: white;
	border-radius: 16px;
	color: black;
	font-family: 'Body';
	font-size: 20px;
	font-weight: 500;
	margin: 10vh 0 15vh;
	padding: 16px 96px;
	text-transform: uppercase;
	border: 1px solid transparent;
	transition: all 0.5s ease;
	@media (min-width: 1024px) {

	}
	&:hover {
		background: transparent;
		color: white;
		border-color: white;
	}
}
</style>
