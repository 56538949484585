<template>
	<div class="roadmap" id="roadmap">
		<h1 class="fade">Roadmap</h1>
		<div class="swiper mySwiper">
			<div class="swiper-wrapper">
				<div class="swiper-slide">
					<h2>An NFT</h2>
					<p>
						Your unique NFTs will be your Avatar in our Gooniez Gang racing game. The beta version will launch in Q3 2022, all game assets have been in development for 4 months.
					</p>
				</div>
				<div class="swiper-slide">
					<h2>THE GOONIEZ 3D COLLECTABLES</h2>
					<p>
						The Gooniez community will vote to choose 4 NFTs from the collection to turn into 3D collectibles. These collectibles will be limited, some gifted while some are sold only to our holders.
					</p>
				</div>
				<div class="swiper-slide">
					<h2>THE MERCH</h2>
					<p>
						The Gooniez Gang merch store will unlock multiple drops over the year. 25% of all sales proceeds will be added to the Gooniez community wallet. This wallet will be dedicated to funding the Gooniez Gang community functions.
					</p>
				</div>
				<div class="swiper-slide">
					<h2>OWNERSHIP</h2>
					<p>
						Own your Gooniez OBJ and FBX files. These files will be handed over to you with all intellectual property rights to your character.
					</p>
				</div>
				<div class="swiper-slide">
					<h2>EARN</h2>
					<p>
						$GANG TOKENS $GANG our in-game token! More information on this will be announced, stick around!
					</p>
				</div>
				<div class="swiper-slide">
					<h2>ANIMATED SERIES</h2>
					<p>
						As the Gooniez gang takes over the metaverse, our cameras will be there to capture all the action. Our animated series will provide you with an in-depth look at the Gooniez origin and story leading up to the game.
						<br>
						SERIES HINT: other NFT projects beware -  we are coming to cause CHAOS!
					</p>
				</div>
				<div class="swiper-slide">
					<h2>GOONIEZ EVENTS</h2>
					<p>
						Exclusive experience for token holders! Soon after mint, there will be a raffle for some round trips with our holders to meet the team, record the content and a lot more!
					</p>
				</div>
				<div class="swiper-slide">
					<h2>METAVERSE TAKEOVER</h2>
					<p>
						The Gooniez Gang racing game will take the Meta-verse by storm. Choose your customised vehicle with your Gooniez character and battle it out on the race track backed by Web 3.0 technology. Using layer 2 solutions and a play to earn eco system you can earn points and coins in exchange for weapons and a decked out car while playing.
					</p>
				</div>
			</div>
			<div class="swiper-button-prev">
				<img src="../assets/arrow-left.svg" />
			</div>
			<div class="swiper-button-next">
				<img src="../assets/arrow-right.svg">
			</div>
		</div>
	</div>
</template>

<script>
  import { isMobile } from 'mobile-device-detect'
  export default {
    components: {
    },
    computed: {
      isMobile() {
        return isMobile ? true : false
      }
    },
    mounted() {
		new window.Swiper(".mySwiper", {
			slidesPerView: isMobile ? 1 : 3,
			spaceBetween: 30,
			centeredSlides: true,
			initialSlide: 1,
			pagination: {
			el: ".swiper-pagination",
				clickable: true,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});
    }
  };
</script>

<style lang="scss" scoped>

	.roadmap {
		padding-top: 15vh;
		@media (min-width: 1024px) {
			padding-top: 0;
		}
		& h1 {
			text-align: center;
			color: white;
			margin: 10vh 0;
			font-size: 3.5rem;
			@media (min-width: 1024px) {
				font-size: 7rem;
			}
		}
	}
	.swiper {
		height: 65vh;
		width: 90%;
		@media (min-width: 1024px) {
			margin-left: 5vw;
			width: 100%;
		}
	}

	.swiper-slide {
		background: #161616;
		border-radius: 45px;
		box-shadow: 0px 4px 4px #000000;
		filter: drop-shadow(5px 8px 37px #000000);
		flex-direction: column;
		-ms-flex-align: center;
		-ms-flex-pack: center;
		-webkit-box-align: center;
		-webkit-box-pack: center;
		display: -ms-flexbox;
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
		font-size: 18px;
		text-align: center;
		z-index: 1;
		height: 52vh;
		@media (min-width: 1024px) {
			max-width: 30vw;
			margin-right: 1vw;
		}
		
		& h2 {
			font-family: 'Soulcraft', sans-serif;
			color: white;
			font-size: 30px;
			padding: 0 32px;
			margin-bottom: 10px;
			height: 12vh;
			@media (min-width: 1024px) {
				font-size: 32px;
				padding: 0 64px;
				margin-bottom: 10px;
				height: 12vh;
			}
		}
		& p {
			font-family: 'Body';
			color: white;
			font-size: 14px;
			padding: 0 32px;
			@media (min-width: 1024px) {
				padding: 0 64px;
			}
			@media (min-width: 1600px) {
				font-size: 16px;
			}
		}
	}
	.swiper-button-next {
		background: #626262;
		border-radius: 12px;
		padding: 0 10px;
		position: absolute;
		transition: all 0.5s ease;
		margin-top: 22vh;
		@media (min-width: 1024px) {
			right: 8vw;
		}
		& img {
			max-width: 20px !important;
		}
		&:hover {
			background: #404040;
		}
		&::after {
			display: none;
		}
	}
	.swiper-button-prev {
		background: #626262;
		border-radius: 12px;
		padding: 0 10px;
		position: absolute;
		transition: all 0.5s ease;
		margin-top: 22vh;
		left: 60vw;
		@media (min-width: 1024px) {
			left: 85vw;
		}
		&:hover {
			background: #404040;
		}
		& img {
			max-width: 20px !important;
		}
		&::after {
			display: none;
		}
	}
</style>