<template>
	<div class="the-story" id="the-story">
		<div class="header-line">
			<h1 class="fade">The Story</h1>
		</div>
		<p class="fade">
			Gooniez Gang, assembled and born from the chaos. Since eternity started the ducks have been hunted, caged, and sacrificed. Flying the skies hasn't been safe and the ducks were on the verge of extinction. Locked away in a deserted lab while being experimented on one mad scientist decided to switch the formula, Gooniez were born.
		</p>
		<div class="badge">
			<img src="../assets/badge.png" alt="Badge">
		</div>
		<!-- <section class="tiles tiles--rotated" id="grid2">
			<div class="tiles__wrap">
				<div class="tiles__line" data-scroll data-scroll-speed="1" data-scroll-target="#grid2" data-scroll-direction="horizontal">
					<div class="tiles__line-img"></div>
					<div class="tiles__line-img"></div>
					<div class="tiles__line-img"></div>
					<div class="tiles__line-img" style="background-image:url('./roadmap1.png')"></div>
					<div class="tiles__line-img" style="background-image:url('./roadmap2.png')"></div>
					<div class="tiles__line-img" style="background-image:url('./team.png')"></div>
				</div>
				<div class="tiles__line" data-scroll data-scroll-speed="-1" data-scroll-target="#grid2" data-scroll-direction="horizontal">
					<div class="tiles__line-img"></div>
					<div class="tiles__line-img" style="background-image:url('./roadmap2.png')"></div>
					<div class="tiles__line-img" style="background-image:url('./roadmap1.png')"></div>
					<div class="tiles__line-img" style="background-image:url('./team.png')"></div>
					<div class="tiles__line-img" style="background-image:url('./roadmap1.png')"></div>
					<div class="tiles__line-img" style="background-image:url('./roadmap2.png')"></div>
				</div>
				<div class="tiles__line" data-scroll data-scroll-speed="1" data-scroll-target="#grid2" data-scroll-direction="horizontal">
					<div class="tiles__line-img" style="background-image:url('./roadmap1.png')"></div>
					<div class="tiles__line-img" style="background-image:url('./roadmap2.png')"></div>
					<div class="tiles__line-img" style="background-image:url('./team.png')"></div>
					<div class="tiles__line-img" style="background-image:url('./roadmap1.png')"></div>
					<div class="tiles__line-img" style="background-image:url('./roadmap2.png')"></div>
					<div class="tiles__line-img" style="background-image:url('./team.png')"></div>
				</div>
				<div class="tiles__line" data-scroll data-scroll-speed="-1" data-scroll-target="#grid2" data-scroll-direction="horizontal">
					<div class="tiles__line-img" style="background-image:url('./team.png')"></div>
					<div class="tiles__line-img" style="background-image:url('./roadmap1.png')"></div>
					<div class="tiles__line-img" style="background-image:url('./roadmap2.png')"></div>
					<div class="tiles__line-img" style="background-image:url('./team.png')"></div>
					<div class="tiles__line-img" style="background-image:url('./roadmap1.png')"></div>
					<div class="tiles__line-img"></div>
				</div>
				<div class="tiles__line" data-scroll data-scroll-speed="1" data-scroll-target="#grid2" data-scroll-direction="horizontal">
					<div class="tiles__line-img"></div>
					<div class="tiles__line-img" style="background-image:url('./roadmap1.png')"></div>
					<div class="tiles__line-img" style="background-image:url('./roadmap2.png')"></div>
					<div class="tiles__line-img" style="background-image:url('./team.png')"></div>
					<div class="tiles__line-img"></div>
					<div class="tiles__line-img"></div>
				</div>
			</div>
		</section> -->
	</div>
</template>

<script>

export default {
}
</script>

<style lang="scss" scoped>
  
.the-story {
	align-items: center;
	background: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	padding: 0;
	height: max-content;
	@media (min-width: 1400px) {
		padding: 10vh 0 0;
		margin-bottom: 10vh;
	}
	& .header-line {
		height: max-content;
		padding: 5vh 0 2vh;
		width: 100%;
		position: relative;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		@media (min-width: 1024px) {
			flex-direction: row;
			justify-content: center;
			height: 150px;
			padding: unset;
		}
	}
	& h1 {
		font-family: 'Soulcraft', sans-serif;
		display: flex;
		align-items: center;
		text-align: center;
		text-transform: uppercase;
		color: #FFFFFF;
		margin: 0 auto;
		font-size: 3rem;
		justify-content: space-between;
		@media (min-width: 1024px) {
			position: absolute;
			font-size: 7rem;
			line-height: 7rem;
		}
	}
	& p {
		font-family: 'Body';
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 154.2%;
		text-align: center;
		text-transform: uppercase;
		color: #FFFFFF;
		width: 80vw;
		@media (min-width: 1024px) {
			max-width: 40vw;
		}
	}
	& .wrapper{
		align-items: center;
		color: #FFFFFF;
		color: white;
		display: flex;
		flex-direction: column;
		font-family: 'Body';
		font-size: 16px;
		font-style: normal;
		font-weight: 300;
		justify-content: center;
		margin-left: -50vw;
		width: 100vw;
		@media (min-width: 1024px) {
			margin-left: -22vw;
		}
	}

	.wrapper .line{
		height: 30px;
		width: 100%;
		position: relative;
		overflow: hidden;
	}

	.wrapper .line span {
		position: absolute;
		font-size: 1rem;
		line-height: 1rem;
	}
	& .button-line {
		height: 120px;
		width: 100%;
		position: relative;
		overflow: hidden;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
}

.badge {
	position: absolute;
	top: 2%;
	right: -35px;
	z-index: 1;

	@media (min-width: 1024px) {
		top: 5%;
		right: -65px;
	}

	img {
		height: 12vh;

		@media (min-width: 1024px) {
			height: 16vh;
		}
	}
}
.tiles {
	--tiles-height: 45vw;
	height: var(--tiles-height);
	position: relative;
	overflow: hidden;
	box-sizing: border-box;
    width: 100%;
	margin: 5rem 0 0;
	@media (min-width: 1024px) {
		--tiles-height: 25vw;
	}
}

.tiles--columns-rotated {
	--tiles-height: 180vmax;
}

.tiles--columns {
	--tiles-height: 140vh;
}

.tiles--oneline {
	--tiles-height: 50vh;
	min-height: 400px;
	margin: 10vh 0;
}

.tiles--small {
	--tiles-height: 100px;
	margin: 10vh 0 0;
}

.tiles--fixed {
	--tiles-height: 65vw;
	margin-top: 25vh;
}

.tiles--perspective {
	overflow: visible;
	perspective: 1000px;
	--tiles-height: 500px;
}

.tiles__wrap {
	width: 150%;
	--tileswrap-height: var(--tiles-height);
	height: var(--tileswrap-height);
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate3d(-50%,-50%, 0);
}

.tiles--rotated .tiles__wrap {
	--tileswrap-height: calc(var(--tiles-height) * 1.8);
	transform: translate3d(-50%,-50%, 0) rotate(22.5deg);
}

.tiles--columns-rotated .tiles__wrap {
	width: 150%;
	display: flex;
	transform: translate3d(-50%,-50%,0) rotate(-22.5deg);
	left: 50%;
	top: 50%;
	align-items: center;
	justify-content: center;
}

.tiles--columns .tiles__wrap {
	width: 100%;
	display: flex;
	--tileswrap-height: calc(var(--tiles-height) * 1.4);
	transform: translate3d(0,-50%,0);
	left: 0;
	top: 50%;
}

.tiles--perspective .tiles__wrap {
	width: 200%;
    transform: translate3d(-50%,-50%,0) translateX(-25%) translateZ(-1200px) rotateX(75.5deg) rotateZ(12deg);
}

.tiles--fixed .tiles__wrap {
	width: 100%;
}

.tiles__line {
	display: flex;
}

.tiles--columns .tiles__line {
	width: 25%;
	padding: 0 1rem;
	display: block;
}

.tiles--columns-rotated .tiles__line {
	width: 320px;
	flex: none;
	padding: 0 1.5vw;
	display: block;
}

.tiles--columns-rotated .tiles__line:nth-child(even) {
	margin-top: -160px;
}

.tiles--oneline .tiles__line {
	height: 100%;
}

.tiles--small .tiles__line {
	height: 100%;
}

.tiles__line-img {
	--tile-margin: 2vw;
	flex: none;
	width: calc(16.6666% - var(--tile-margin) * 2);
	height: calc( var(--tileswrap-height) - (2 * var(--tile-margin)));
	margin: var(--tile-margin);
	background-size: cover;
	background-position: 50% 50%;
}

.tiles--perspective .tiles__line-img {
	backface-visibility: hidden;
	outline: 1px solid transparent;
	--tile-margin: 1vw;
	width: calc(16.6666% - var(--tile-margin) * 2);
	height: calc(16.666vw * 1.3);
}

.tiles--oneline .tiles__line-img {
	--tile-margin: 1vw;
	margin: 0 var(--tile-margin);
	width: calc(25% - var(--tile-margin) * 2);
	height: 100%;
}

.tiles--small .tiles__line-img {
	--tile-margin: 5px;
	margin: 0 var(--tile-margin);
	width: calc(12.5% - var(--tile-margin) * 2);
	height: 100%;
}

.tiles--rotated .tiles__line-img {
	--tile-margin: 1vw;
	width: calc(16.6666% - var(--tile-margin) * 2);
	height: calc( var(--tileswrap-height) / 3 - (4 * var(--tile-margin) / 2));
	border-radius: 24px;
	@media (min-width: 1024px) {
		height: calc( var(--tileswrap-height) / 3 - (4 * var(--tile-margin) / 2));
	}
}

.tiles--columns-rotated .tiles__line-img {
	--tile-margin: 3vw 0;
	width: 100%;
	height: 400px;
}

.tiles--columns .tiles__line-img {
	--tile-margin: 2rem 0;
	width: 100%;
	height: calc(25vw * 1.3);
}

.tiles--fixed .tiles__line-img {
	--tile-margin: 10px;
	-webkit-filter: brightness(0.8);
	width: calc(16.6666% - var(--tile-margin) * 2);
	height: calc( var(--tileswrap-height) / 3 - (3 * var(--tile-margin) / 2));
}

.tiles--darker .tiles__line-img {
	opacity: 0.7;
}

.tiles__title {
	position: absolute;
	height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 10vw;
	padding: 3rem;
	margin: 0;
	line-height: 0.8;
	font-family: span, serif;
	font-weight: 700;
	font-style: normal;
}

.tiles__title--alt {
	font-size: 9vw;
	font-size: clamp(2rem, 9vw, 7.25rem);
}

.tiles__title--intro {
	padding-top: 10vh;
	align-items: flex-start;
	justify-content: flex-start;
}

.tiles__title--right {
	justify-content: flex-end;
}

.tiles__title--left {
	justify-content: flex-start;
}

.tiles__title--full {
	height: 100%;
}

</style>