<template>
	<div class="testimonials" id="testimonials">
		<a href="https://twitter.com/GooniezNFT" target="_blank" class="mint-cta">
			<img src="../assets/twitter-white.svg" class="twitter-white" /> 
			<img src="../assets/twitter.svg" class="twitter-black" /> 
			Engage
		</a>
		<div class="swiper testimonials-swiper">
			<div class="swiper-wrapper">
				<div class="swiper-slide">
					<a target="_blank" href="https://twitter.com/AmeerHussainn">
						<img src="https://pbs.twimg.com/profile_images/1479875551724769285/ZAenOdou_400x400.jpg" alt="Gooniez Gang Testimonial" class="avatar" />
					</a>
					<h1>Ameer Hussain</h1>
					<a target="_blank" href="https://twitter.com/AmeerHussainn">@AmeerHussainn</a>
					<p>
						“I think this is one of those projects thats gonna do really well”
					</p>
				</div>
				<div class="swiper-slide">
					<a target="_blank" href="https://twitter.com/champtgram">
						<img src="https://pbs.twimg.com/profile_images/1488928816106033152/7Zr92kC6_400x400.jpg" alt="Gooniez Gang Testimonial" class="avatar" />
					</a>
					<h1>champ</h1>
					<a target="_blank" href="https://twitter.com/champtgram">@champtgram</a>
					<p>
						“I think this project is absolutely amazing and I'd be doing you a disservice by not making you aware”
					</p>
				</div>
				<div class="swiper-slide">
					<a target="_blank" href="https://twitter.com/nftpasta">
						<img src="https://pbs.twimg.com/profile_images/1485292581219368964/C8Y-npP8_400x400.jpg" alt="Gooniez Gang Testimonial" class="avatar" />
					</a>
					<h1>NFT Pasta</h1>
					<a target="_blank" href="https://twitter.com/nftpasta">@nftpasta</a>
					<p>
						“Art work is insane, really high quality and I really really like this one. I am a big fan of the quality and the Art behind this one”
					</p>
				</div>
				<div class="swiper-slide">
					<a target="_blank" href="https://twitter.com/ryandcrypto">
						<img src="https://pbs.twimg.com/profile_images/1476819800345042944/m8IM_9lv_400x400.jpg" alt="Gooniez Gang Testimonial" class="avatar" />
					</a>
					<h1>ryandcrypto</h1>
					<a target="_blank" href="https://twitter.com/ryandcrypto">@ryandcrypto</a>
					<p>
						“There is clearly a lot of demand for this project”
					</p>
				</div>
				<div class="swiper-slide">
					<a target="_blank" href="https://twitter.com/KosherPlug">
						<img src="https://pbs.twimg.com/profile_images/1472477878537367553/9Mr1vNhC_400x400.jpg" alt="Gooniez Gang Testimonial" class="avatar" />
					</a>
					<h1>Kosher Plug</h1>
					<a target="_blank" href="https://twitter.com/KosherPlug">@kosherplug</a>
					<p>
						“Art is phenomenal, this is a project I like”
					</p>
				</div>
				<div class="swiper-slide">
					<a target="_blank" href="https://twitter.com/BentoBoiNFT">
						<img src="https://pbs.twimg.com/profile_images/1488749186610728960/4POimDrS_400x400.jpg" alt="Gooniez Gang Testimonial" class="avatar" />
					</a>
					<h1>BentoBoi</h1>
					<a target="_blank" href="https://twitter.com/BentoBoiNFT">@bentoboinft</a>
					<p>
						“I think this project has alot of National hype, the Roadmap is ambitious and gets me hyped about the project”
					</p>
				</div>
				<div class="swiper-slide">
					<a target="_blank" href="https://twitter.com/NFTNate_">
						<img src="https://pbs.twimg.com/profile_images/1484395203901685760/mKJPrz56_400x400.jpg" alt="Gooniez Gang Testimonial" class="avatar" />
					</a>
					<h1>NFT Nate</h1>
					<a target="_blank" href="https://twitter.com/NFTNate_">@NFTNate_</a>
					<p>
						“Gooniez is so damn hot, I'm excited for this project”
					</p>
				</div>
				<div class="swiper-slide">
					<a target="_blank" href="https://twitter.com/DonteCrypto">
						<img src="https://pbs.twimg.com/profile_images/1482848375917916160/DRx4N51X_400x400.png" alt="Gooniez Gang Testimonial" class="avatar" />
					</a>
					<h1>Donte Walker</h1>
					<a target="_blank" href="https://twitter.com/DonteCrypto">@DonteCrypto</a>
					<p>
						“A project that i really look forward to to be apart of”
					</p>
				</div>
				<!-- <div class="swiper-slide">
					<a target="_blank" href="https://twitter.com/thebrettway">
						<img src="https://pbs.twimg.com/profile_images/1438542071611236355/8UfAag7j_400x400.jpg" alt="Gooniez Gang Testimonial" class="avatar" />
					</a>
					<h1>Brett Malinowski</h1>
					<a target="_blank" href="https://twitter.com/thebrettway">@thebrettway</a>
					<p>
					</p>
				</div> -->
				<div class="swiper-slide">
					<a target="_blank" href="https://twitter.com/MattCobuzio">
						<img src="https://pbs.twimg.com/profile_images/1484704259341910016/gPnzDZlY_400x400.png" alt="Gooniez Gang Testimonial" class="avatar" />
					</a>
					<h1>Matt's Crypto</h1>
					<a target="_blank" href="https://twitter.com/MattCobuzio">@MattCobuzio</a>
					<p>
						“My community brought this to me and I loved every part of it”
					</p>
				</div>
				<!-- <div class="swiper-slide">
					<a target="_blank" href="https://twitter.com/xdoubleq">
						<img src="https://pbs.twimg.com/profile_images/1488342188723343360/VgvhREuY_400x400.jpg" alt="Gooniez Gang Testimonial" class="avatar" />
					</a>
					<h1>DoubleQ.eth</h1>
					<a target="_blank" href="https://twitter.com/xdoubleq">@xdoubleq</a>
					<p>
					</p>
				</div> -->
				<!-- <div class="swiper-slide">
					<a target="_blank" href="https://twitter.com/khoshy">
						<img src="https://pbs.twimg.com/profile_images/1488690619207675904/C-u9ou_5_400x400.png" alt="Gooniez Gang Testimonial" class="avatar" />
					</a>
					<h1>Khosh</h1>
					<a target="_blank" href="https://twitter.com/khoshy">@khoshy</a>
					<p>
					</p>
				</div> -->
				<!-- <div class="swiper-slide">
					<a target="_blank" href="https://twitter.com/TheDogePoundNFT">
						<img src="https://pbs.twimg.com/profile_images/1484245421560107014/DBNwF5tN_400x400.png" alt="Gooniez Gang Testimonial" class="avatar" />
					</a>
					<h1>Doge Pound Studios</h1>
					<a target="_blank" href="https://twitter.com/TheDogePoundNFT">@TheDogePoundNFT</a>
					<p>
					</p>
				</div> -->
				<!-- <div class="swiper-slide">
					<a target="_blank" href="https://twitter.com/JRNYclub">
						<img src="https://pbs.twimg.com/profile_images/1458152003104763904/T_xE-EXt_400x400.jpg" alt="Gooniez Gang Testimonial" class="avatar" />
					</a>
					<h1>JRNY NFT CLUB</h1>
					<a target="_blank" href="https://twitter.com/JRNYclub">@JRNYclub</a>
					<p>
					</p>
				</div> -->
				
			</div>
			<div class="swiper-button-prev">
				<img src="../assets/arrow-left.svg" />
			</div>
			<div class="swiper-button-next">
				<img src="../assets/arrow-right.svg" alt="">
			</div>
		</div>
	</div>
</template>

<script>

import { isMobile } from 'mobile-device-detect'

export default {
	computed: {
		isMobile() {
			return isMobile ? 1 : 4;
		}
	},
	mounted() {
      new window.Swiper(".testimonials-swiper", {
        slidesPerView: isMobile ? 1 : 4,
		spaceBetween: 30,
		autoplay: {
			delay: 5000
		},
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
	}
}
</script>

<style lang="scss" scoped>
.testimonials {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.mint-cta {
	background: transparent;
	border-radius: 16px;
	color: white;
	font-family: 'Body';
	font-size: 20px;
	font-weight: 500;
	padding: 16px 96px;
	text-transform: uppercase;
	border: 1px solid white;
	transition: all 0.5s ease;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 20vw;
	& img {
		margin-right: 1vw;
		width: 20px;
	}
	& .twitter-black {
		display: none;
	}
	&:hover {
		background: white;
		color: black;
		border-color: black;
		& .twitter-black { 
			display: block;
		}
		& .twitter-white {
			display: none;
		}
	}
}
	.testimonials-swiper {
		width: 90%;
		height: 100%;
		margin-bottom: 10vh;
		margin-top: 5vh;
		@media (min-width: 1024px) {
			width: 95%;
		}
	}
	
	.swiper-slide {
		text-align: center;
		font-size: 18px;

		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: flex-start;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
		flex-direction: column;

		border-radius: 24px;
		@media (min-width: 1024px) {
			height: 45vh;
		}
		& img {
			width: 150px !important;
			height: 150px !important;
			border-radius: 50%;
			object-fit: cover;
		}
		& h1 {
			color: white;
			margin-bottom: 0;
		}
		& a {
			font-family: 'Body';
			text-transform: uppercase;
			color: #c4c4c4;
			font-size: 14px;
			transition: all 0.5s ease;;
			&:hover {
				color: white;
			}
		}
		& p {
			color: white;
			font-family: 'Body';
			max-width: 200px;
			font-size: 12px;
			@media (min-width: 1024px) {
				max-width: 250px;
				font-size: 14px;
			}
		}
	}

	.swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.swiper-button-next {
	background: #626262;
	border-radius: 12px;
	padding: 0 10px;
	transition: all 0.5s ease;
	margin-top: 17vh;
	@media (min-width: 1024px) {
		margin-top: 15vh;
	}
    & img {
      max-width: 20px !important;
    }
    &:hover {
      background: #404040;
    }
    &::after {
      display: none;
    }
  }
  .swiper-button-prev {
    background: #626262;
    border-radius: 12px;
    padding: 0 10px;
    transition: all 0.5s ease;
	margin-top: 17vh;
	@media (min-width: 1024px) {
		margin-top: 15vh;
		left: 87vw;
	}
    &:hover {
      background: #404040;
    }
    & img {
      max-width: 20px !important;
    }
    &::after {
      display: none;
    }
  }
</style>