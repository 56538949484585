<template>
  <div id="app">
    <WalletPopup
      :z-index="1000" 
      v-show="isConnectVisible"
      @show-guide-modal="showGuideModal"
      @close="closeConnectModal" />

    <WalletGuidePopup
        :z-index="1001"
        v-show="isGuideVisible"
        @close="closeGuideModal" />

    <div>
      <Header 
        v-if="$store.getters.getIsPageFrameVisible"
        @connect="showConnectModal"
      />
      <router-view class="main" @connect="showConnectModal"></router-view>
      <Footer 
        v-show="$store.getters.getIsPageFrameVisible"
      />
      <div class="cursor"></div>
      <div class="cursor-follower"></div>
    </div>
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import WalletPopup from './components/WalletPopup'
import store from './store'
import WalletGuidePopup from './components/WalletGuidePopup'
import { gsap } from 'gsap'

export default {
  components: {
    Header,
    Footer,
    WalletPopup,
    WalletGuidePopup
  },

  watch: {
    $route: (to) => {
      if (to.path == '/') {
        store.commit('setPageFrame', {
            isPageFrameVisible: true
        });
      }
    }
  }, 

  mounted() {
    // CURSOR
    const cursor = document.querySelector('.cursor');
    const follower = document.querySelector('.cursor-follower');

    let posX = 0,
        posY = 0;

    let mouseX = 0,
        mouseY = 0;

    gsap.to({}, 0.016, {
      repeat: -1,
      onRepeat: function() {
        posX += (mouseX - posX) / 9;
        posY += (mouseY - posY) / 9;

        gsap.set(follower, {
            left: posX - 23,
            top: posY - 23
        });

        gsap.set(cursor, {
            left: mouseX,
            top: mouseY
        });
      }
    });

    document.addEventListener('mousemove', e => {
        mouseX = e.clientX;
        mouseY = e.clientY;
    })
  },

  data() {
    return {
      isGuideVisible: false,
      isConnectVisible: false,
    };
  },
  methods: {
    showGuideModal() {
      this.isGuideVisible = true;
    },
    showConnectModal() {
      this.isConnectVisible = true;
    },
    closeGuideModal() {
      this.isGuideVisible = false;
    },
    closeConnectModal(success) {
      this.isConnectVisible = false;
      if (success) {
        this.$router.push({ path: '/mint' })
      }
    }
  }

}
</script>
<style lang="scss">
@import '/node_modules/locomotive-scroll/src/locomotive-scroll.scss';

.c-scrollbar {
  z-index: 999;

  &_thumb{
    background: white;
  }
}

.cursor {
    position: fixed;
    background-color: white;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    z-index: 1;
    transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
        0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
    user-select: none;
    pointer-events: none;
    z-index: 10000;
    transform: scale(1);
    display: none;
    @media (min-width: 1024px) {
      display: block;
    }

    &.active {
        opacity: 1;
        transform: scale(0);
    }

    &.menu-active {
        opacity: 1;
        transform: scale(0);
    }

    &.hovered {
        opacity: 1;
    }
}

.cursor-follower {
    position: fixed;
    border:1px solid white;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    z-index: 1;
    transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
        0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) background;
    user-select: none;
    pointer-events: none;
    z-index: 10000;
    transform: translate(2px, 2px);
    //overflow: hidden;
    display: none;
    @media (min-width: 1024px) {
      display: block;
    }
    &.active {
        opacity: 1;
        transform: scale(3);
    }

    &.menu-active {
        opacity: 1;
        transform: scale(3);
    }

    &.hovered {
        opacity: 1;
    }

}


@font-face {
  font-family: 'Soulcraft';
  src: url('https://uploads-ssl.webflow.com/61f25bf46b3218708480f622/61f25e2875cb9fb559da4638_ClashDisplay-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Body';
  src: url('./assets/body.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


html, body {
  margin: 0;
  padding: 0;
  --c-lime: #C0FF34;
  overflow-x: hidden !important;
  cursor: none;
  background: black;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Soulcraft';
  font-weight: 100;
  text-transform: uppercase !important;
}

p {
  text-transform: uppercase !important;
  line-height: 1.6 !important;
}

a {
  text-decoration: none;
}

#app {
  font-family: 'Soulcraft', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
}

#nav {
  padding: 30px;
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
