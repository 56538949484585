
import Vuex from 'vuex'
import Vue from 'vue'
import CONFIG from './config/config.json';
import ABI from './config/abi.json';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
      publicAddress: null,
      config: CONFIG.NETWORK,
      contractAddress: CONFIG.CONTRACT_ADDRESS,
      whiteListStart: CONFIG.WHITE_LIST_START,
      publicSaleStart: CONFIG.PUBLIC_SALE_START,
      abi: ABI,
      web3: null,
  
      weiCost: CONFIG.WEI_COST,
      whiteListWeiCost: CONFIG.WHITE_LIST_WEI_COST,
      displayCost: CONFIG.DISPLAY_COST,
      displayWhiteListCost: CONFIG.WHITE_LIST_COST,
  
      gasLimit: CONFIG.GAS_LIMIT,
      maxMintPerPerson: CONFIG.MAX_MINT_PER_PERSON,
      isPageFrameVisible: true,
      smartContract: null,
    },
    getters: {
      getSmartContract: state => {
        return state.smartContract;
      },
  
      getWhiteListStart: state => {
        return state.whiteListStart;
      },
  
      getPublicSaleStart: state => {
        return state.publicSaleStart;
      },
  
      getWhiteListWEICost: state => {
        return state.whiteListWeiCost;
      },
  
      getWEICost: state => {
        return state.weiCost;
      },
  
      getDisplayCost: state => {
        return state.displayCost;
      },
  
      getDisplayWhiteListCost: state => {
        return state.displayWhiteListCost;
      },
  
      getGasLimit: state => {
        return state.gasLimit;
      },
  
      getWeb3: state => {
        return state.web3;
      },
  
      getPublicAddress: state => {
        return state.publicAddress;
      },
  
      getMaxMintAmount: state => {
        return state.maxMintPerPerson;
      },
  
      getContractAddress: state => {
        return state.contractAddress;
      },
  
      getAbi: state => {
        return state.abi;
      },
  
      getIsPageFrameVisible: state => {
        return state.isPageFrameVisible;
      },
  
      getConfigID: state => {
        return state.config.ID;
      },
  
      getDisplayPublicAddress: state => {
        if (state.publicAddress) {
          return state.publicAddress.substring(0,5) + '...';
        }
        return null;
      }
    },
    mutations: {
      setPublicAddress (state, { publicAddress }) {
        state.publicAddress = publicAddress;
      },
  
      setPageFrame (state, { isPageFrameVisible }) {
        state.isPageFrameVisible = isPageFrameVisible;
      },
  
      setSmartContract(state, { smartContract }) {
        state.smartContract = smartContract;      
      },
  
      setWeb3(state, { web3 }) {
        state.web3 = web3;
      }
    }
  });

  export default store;