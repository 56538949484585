<template>
    <div class="container-mint">
        <div class="top-bar">
            <router-link to="/">
                <img src="../assets/eye_logo.svg" alt="">
            </router-link>
            <button class="btn-connect mt-2" @click="$emit('connect')">{{ account ? account : 'Connect' }}</button>
        </div>
        <div class="nft-image-container">
            <div class="nft-image">
            </div>
            
            <p>{{feedBackMessage}}</p>
            <p>{{ displayCost }} ETH (EXCL. GAS)</p>
            <div class="control-container mt-2">
                <div class="number-input-container mr-2">
                    <button
                        type="button"
                        class="button-decrement"
                        @click="decrementAmount"
                        data-input-id="hue"
                        data-operation="decrement"
                    >
                        <img src="../assets/minus.svg" alt="" />
                    </button>
                    <div class="number-input">
                        <input
                            type="number"
                            id="hue"
                            name="hue"
                            class="number-input-text-box"
                            :value="mintAmount"
                            min="1"
                            :max="$store.getters.getMaxMintAmount"
                            oninput="handleNumberInput()"
                            onblur="handleNumberInputBlur(event)"
                            data-step="30"
                        />
                    </div>
                    <button
                        type="button"
                        class="button-increment"
                        @click="incrementAmount"
                        data-input-id="hue"
                        data-operation="increment"
                    >
                        <img src="../assets/plus.svg" alt="">
                    </button>
                </div>
                <button :disabled="loading || !account" class="btn-mint" @click="mint">{{ loading ? 'Loading...' : 'Mint' }}</button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  beforeCreate() {
    this.$store.commit('setPageFrame', {
        isPageFrameVisible: false
    });
  },

  data() {
      return {
          mintAmount: 1,
          feedBackMessage: '',
          loading: false,
      }
  },

    methods: {
        decrementAmount() {
            this.mintAmount = this.mintAmount - 1 >= 1 ? this.mintAmount - 1 : this.mintAmount;
        },
        incrementAmount() {
            this.mintAmount = this.mintAmount + 1 <= this.$store.getters.getMaxMintAmount ? this.mintAmount + 1 : this.mintAmount;
        },

        async getNetworkId(provider) {
            return await provider.request({
                method: "net_version",
            });
        },

        async mint() {
            let smartContract = this.$store.getters.getSmartContract;
            if (smartContract) {
                this.loading = true;
                const totalGasLimit = String(this.$store.getters.getGasLimit * this.mintAmount);
                const totalCostWei = String(this.cost * this.mintAmount);

                const web3 = this.$store.getters.getWeb3;
                smartContract.setProvider(web3.currentProvider);

                let provider = web3.currentProvider;
                const networkId = this.getNetworkId(provider);
                if (networkId != this.$store.getters.getConfigID) {
                    try {
                        await provider.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: `0x${this.$store.getters.getConfigID}` }], // chainId must be in hexadecimal numbers
                        });
                    } catch (err) {
                        console.log({ err });
                        alert('Please change to the Ethereum Mainnet');
                        this.loading = false;
                        return;
                    }
                }

                
                smartContract.methods
                    .mint(this.mintAmount).send({
                        gasLimit: String(totalGasLimit),
                        to: this.$store.getters.getContractAddress,
                        from: this.$store.getters.getPublicAddress,
                        value: totalCostWei,
                    })
                    .on("error", (err) => {
                        console.log({err});
                        this.feedBackMessage = "Sorry, something went wrong please try again later.";
                        this.loading = false;
                    })
                    .on('receipt', () => {
                        this.feedBackMessage = `Welcome to the Gooniez Gang! Go visit Opensea.io to view your NFT.`
                        this.loading = false;
                    });
            }
        }
    },

    computed: {
        account() {
            return this.$store.getters.getDisplayPublicAddress;
        },
        isWhiteList() {
            let whiteListStart = this.$store.getters.getWhiteListStart;
            let publicSaleStart = this.$store.getters.getPublicSaleStart;

            let whiteListDate = new Date(whiteListStart*1000);
            let publicSaleDate = new Date(publicSaleStart*1000);

            let now = new Date();

            return now > whiteListDate && now < publicSaleDate;
        },

        cost() {
            let whiteSaleCost = this.$store.getters.getWhiteListWEICost;
            let publicSaleCost = this.$store.getters.getWEICost;
            return this.isWhiteList ? whiteSaleCost : publicSaleCost;
        },

        displayCost() {
            let whiteSaleCost = this.$store.getters.getDisplayWhiteListCost;
            let publicSaleCost = this.$store.getters.getDisplayCost;
            return this.isWhiteList ? whiteSaleCost : publicSaleCost;
        }
    }
};

</script>

<style lang="scss">

.container-mint {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
}

.control-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 5vh;
}

.number-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid white;
    border-radius: 50px;
    height: 50px;
    & button {
        height: 20px;
        font-size: 18px;
        background: black;
        color: white;
        outline: none;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        & img {
            max-width: 20px;
        }
    }
    & input {
        background: transparent;
        outline: none;
        border: none;
        color: white;
        margin: 0 10px;
        font-family: 'Body';
        font-size: 18px;
    }
}

.mt-2 {
    margin-top: 12px;
}

.mt-4 {
    margin-top: 16px;
}

.mr-2 {
    margin-right: 8px;
}

.top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & a {
        max-width: 20vw;
        @media (min-width: 1024px) {
            max-width: 100px;
            height: 100px;
        }
    }
    & img {
        width: 100%;
    }
}

.nft-image-container {
    height: 100vh;
    display: inline-block;
    margin-top: 5vh;
}

.nft-image-container p {
    color: white;
    font-family: 'Body';
}

.nft-image {
    display: inline-flex;
    height: 40vh;
    width: 80vw;
    background: url('../assets/slide2.png');
    border-radius: 40px;

    @media (min-width: 1024px) { 
        border-radius: 20px;
        width: 500px;
        height: 500px;
    }
}

.btn-connect {
	background: transparent;
	border-radius: 100px;
	border: 1px solid white;
	color: white;
	cursor: pointer;
	font-family: 'Soulcraft';
	font-weight: 400;
	margin-right: 15px;
	outline: none;
	padding: 7px 20px;
	text-transform: uppercase;
	transition: all 0.5s ease;
	width: 35vw;
	&:hover {
		color: black;
		background: white;
		border-color: white;
	}
	@media (min-width: 1024px) { 
		padding: 10px 5vw 15px;
		font-size: 24px;
		width: 20vw;
		margin-right: 30px;
	}
}

.btn-mint {
    background: transparent;
    border: 1px solid white;
    font-family: 'Soulcraft';
    padding: 10px 5vw 15px;
    color: white;
    border-radius: 50px;
    font-size: 1.5rem;
    transition: all 0.5s ease;
    cursor: pointer;
    &:hover {
        background: white;
        color: black;
    }
}

</style>